import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form } from "./Comp/Form";
import { DispatchStore } from "./Store/DispatchStore";
import { ModalWrapper } from "./Comp/modal/ModalWrapper";
import { IDispatchTab } from "../..";
import { ModalContainer, Nav } from "ms-ui";
import { ModalLoader } from "ms-ui";
import { isNil } from "ms_data";

// 기사님 정보 Modal
export const DrInfo = observer((props: IDispatchTab) => {
  const { modalStore } = props;
  const [dispatchStore] = React.useState(new DispatchStore());

  useEffect(() => {
    dispatchStore.loadData(modalStore?.select);
  }, []);

  useEffect(() => {
    const currentScrollY = window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.width = "100%";

    return () => {
      document.body.style.position = "";
      document.body.style.width = "";
      window.scrollTo(0, currentScrollY);
    };
  }, []);

  return (
    <ModalContainer>
      <ModalLoader active={dispatchStore.isLoading} />

      {/* 기사님 정보 */}
      {!isNil(dispatchStore.data) ? (
        <>
          <Nav
            left={{
              type: "back",
              onClick: () => modalStore?.setSelect(null),
            }}
            title={"기사님 정보"}
          />
          <Form modalStore={modalStore!} dispatchStore={dispatchStore} />
        </>
      ) : null}
    </ModalContainer>
  );
});
