import { observer } from "mobx-react";
import { IPaidTab } from "../..";
import { IStorePaid } from "../../../Store/Interface/IPaidTabStore";

import { spacing } from "ms-ui";
import styled from "styled-components";
import { EmptyList } from "../../../Style";
import { PaidCardTpl } from "../PaidCardTpl";

export const List = observer((props: IPaidTab) => {
  const { paidTabStore, modalStore } = props;
  const { data } = paidTabStore;

  return (
    <>
      <StList>
        {data?.paidList.length! > 0 ? (
          <>
            {data?.paidList.map((c: IStorePaid) => (
              <PaidCardTpl
                {...props}
                {...c}
                key={c.paid.paidId}
                modalStore={modalStore!}
              />
            ))}
          </>
        ) : (
          <EmptyList>결제 내역이 없습니다.</EmptyList>
        )}
      </StList>
    </>
  );
});

const StList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing[8].value};

  & > :first-child {
    margin-top: ${spacing[4].value};
  }
`;
