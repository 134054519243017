import React from "react";
import { observer } from "mobx-react";
import { RefundStore } from "./RefundStore";
import { IRefund, mComma } from "ms_data";
import {
  ModalContainer,
  ModalLoader,
  ModalStore,
  Nav,
  SafeAreaBottom,
  contents,
  spacing,
} from "ms-ui";
import styled from "styled-components";
import { RefundItem } from "./RefundItem";

const refundTitle = {
  kcp: "KCP 환불",
  payco: "PAYCO 환불",
  mshuttle: "계좌 환불",
  naverpay: "NAVERPAY 환불",
  kakaopay: "KAKAOPAY 환불",
};

// 환불 내역
export const RefundList = observer((props: { modalStore: ModalStore }) => {
  const { modalStore } = props;
  const [refundStore] = React.useState(new RefundStore());

  React.useEffect(() => {
    if (modalStore.select) {
      refundStore.loadData(modalStore.select);
    }
  }, []);

  React.useEffect(() => {
    const currentScrollY = window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.width = "100%";

    return () => {
      document.body.style.position = "";
      document.body.style.width = "";
      window.scrollTo(0, currentScrollY);
    };
  }, []);

  return (
    <>
      <ModalContainer>
        <ModalLoader active={refundStore.isLoading} />
        <Nav
          title={`환불내역`}
          left={{
            type: "back",
            onClick: () => props.modalStore.setSelect(null),
          }}
        />

        <StList>
          {refundStore.data?.map((c: IRefund, idx: number) => {
            // return <Refund key={idx} {...c} />;
            const { compnCd, createdAt, amount } = c;
            return (
              <RefundItem
                key={idx}
                title={refundTitle[compnCd]}
                createdAt={createdAt}
                amount={mComma(Math.abs(amount))}
              />
            );
          })}
        </StList>
      </ModalContainer>
    </>
  );
});

export const StList = styled.div`
  ${SafeAreaBottom()}
  background-color: ${contents.background.value};
  height: calc(100dvh - 56px);
  display: flex;
  flex-direction: column;
  gap: ${spacing[8].value};
  overflow: auto;
  overscroll-behavior: contain;

  & > :first-child {
    margin-top: ${spacing[4].value};
  }

  & > :last-child {
    margin-bottom: ${spacing[4].value};
  }
`;
