import { observer } from "mobx-react";
import { PaidCard } from "./PaidCard";
import { isNil, mComma, openReceipt } from "ms_data";
import { MSHUTTLE_DATA } from "src/constants/addr";
import { IStorePaid } from "../../../Store/Interface/IPaidTabStore";
import { PaidTabStore } from "../../../Store/PaidTabStore";
import { ModalStore } from "ms-ui";

export interface IPaidProps extends IStorePaid {
  paidTabStore: PaidTabStore;
  modalStore: ModalStore;
}

export const PaidCardTpl = observer((props: IPaidProps) => {
  const { modalStore, paid, refundSumAmount, imp, paidTabStore } = props;

  return (
    <PaidCard
      orderNo={paid.orderNo}
      createdAt={paid.createdAt}
      status={
        paidTabStore.data?.statusCdList
          .find((sc: any) => sc.col === "status_cd")
          ?.value.find((c: any) => c.key === paid.statusCd).ko
      }
      paidAmount={mComma(paid.amount)}
      refundAmount={mComma(Number(refundSumAmount))}
      visible={{
        refundAmount: !isNil(paid) && !isNil(refundSumAmount),
        paycoCaption: paid.providerCd === "payco",
        naverCaption: paid.providerCd === "kakaopay",
        receipt:
          !isNil(imp) && ["kcp", "naverpay"].indexOf(paid.providerCd) > -1,
        refundList: !isNil(refundSumAmount),
        refundQuestion:
          (paid.statusCd === "paid" || paid.statusCd === "partialcancelled") &&
          paid.amount > 0,
      }}
      onClick={{
        receipt: () => openReceipt(paid, imp, MSHUTTLE_DATA.stage),
        refundList: () => modalStore.setSelect(paid.orderNo),
        refundQuestion: () => modalStore.setOpen(true),
      }}
    />
  );
});
